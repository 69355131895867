import QuoteList from './index'

class Raquel extends QuoteList {
  constructor() {
    super('Raquel')
    this.setPhrases(phrases)
    this.setQuotes(quotes)
  }
}

const phrases = [
  
]

const quotes = [
  `It's like I've told you, Cassandra. Women are from Venus, men are from Peckham!`
]

export default Raquel